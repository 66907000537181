@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #1b1b1b;
}

.leaflet-draw {
  postion: absolute;
  bottom: 500px;
}

.leaflet-control-zoom {
  position: absolute;
  max-width: 100vw;
  top: 10vh;
  left: 97vw;
}
.leaflet-new-tooltip {
  width: 300px;
  position: relative;
  top: 200px;
  left: 200px;
  background-color: red;
}

.glass {
  background: #1b1b1b;
  color: #fff;
  font-size: 2rem;
  height: 30px !important;
}

.reset {
  background: #1b1b1b;
  color: white;
  width: 30px;
  height: 30px;
}

.reset:hover {
  background: #1b1b1b !important;
  color: white;
}

.leaflet-touch .leaflet-geosearch-bar form {
  border: none;
  background: #1b1b1b;
  padding: 10px 20px;
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
}

/* .leaflet-control-geosearch {
  width: 30vw !important;
  position: absolute !important;
  left: 20px !important;
  top: 3px !important;
} */

.leaflet-touch .leaflet-control-geosearch a.reset {
  line-height: 50px !important;
  background: #1b1b1b;
  color: white;
  font-size: 1.2rem;
}

.results > * {
  background: #1b1b1b;
  color: white;
  font-size: 1.2rem;
}

.leaflet-control-geosearch .results {
  background: none !important;
  padding: 0px;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #1b1b1b !important;
  border-color: #c6c6c6;
  cursor: pointer;
}

.leaflet-control-geosearch .results > * {
  padding: 10px !important;
}

.leaflet-draw-draw-rectangle {
  display: none !important;
}

.leaflet-draw {
  display: none !important;
}

.leaflet-draw-edit-remove {
  display: none !important;
}

.rdrMonth {
  width: 100% !important;
}

.rdrCalendarWrapper {
  width: 95%;
  border-radius: 7px !important;
}
.rdrDateDisplayWrapper {
  border-radius: 7px !important;
}
.rdrMonthsVertical {
  align-items: center;
}

.leaflet-draw-draw-polygon {
  display: none !important;
}

.pushable {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  transform: translateY(2px);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(340deg 100% 32%);
}
.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  background: hsl(345deg 100% 47%);
  transform: translateY(-4px);
}

.pushable:hover .front {
  transform: translateY(-6px);
}
.pushable:hover .shadow {
  transform: translateY(4px);
}
.pushable:active .front {
  transform: translateY(-2px);
}
.pushable:active .shadow {
  transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}
